:root {
  
  /* Main Settings  */
  --main-background-color: #98BAE7;

  --text-primary-color: rgb(0, 0, 0);
  --text-secondary-color: rgb(0, 0, 0);

  /* Nav Settings  */
  --nav-link-color: rgb(0, 0, 0);
  --nav-link-hover-color: rgb(87, 87, 87);
  --nav-link-hover-background-color: none;
  --nav-background-color: transparent;
  --nav-height: 150px;
  --nav-logo-width: 125px;
  --nav-logo-font-size: 18px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: rgb(255, 255, 255);
  --mobile-nav-logo-height:100px;
  --mobile-nav-link-color: rgb(0, 0, 0);
  --mobile-nav-link-hover-color: rgb(29, 29, 29);
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: rgba(216, 216, 216, 0.062);
  --card-border-radius: 10px;
  --card-shadow: 10px 10px 20px -13px rgb(255, 255, 255);

  /* Minting Area */
  --minting-box-color: #00000096;
  --minting-box-heading-color: #73c2fb;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: #73c2fb;


  /* Minting Button */
  --minting-button-color: linear-gradient(180deg,#73c2fb 0%,#63b8f5 100%);
  --minting-button-text-color: black;

}






