@import url(https://fonts.googleapis.com/css2?family=Source+Serif+4:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+4:wght@300&family=Underdog&display=swap);
:root {
  
  /* Main Settings  */
  --main-background-color: #98BAE7;

  --text-primary-color: rgb(0, 0, 0);
  --text-secondary-color: rgb(0, 0, 0);

  /* Nav Settings  */
  --nav-link-color: rgb(0, 0, 0);
  --nav-link-hover-color: rgb(87, 87, 87);
  --nav-link-hover-background-color: none;
  --nav-background-color: transparent;
  --nav-height: 150px;
  --nav-logo-width: 125px;
  --nav-logo-font-size: 18px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: rgb(255, 255, 255);
  --mobile-nav-logo-height:100px;
  --mobile-nav-link-color: rgb(0, 0, 0);
  --mobile-nav-link-hover-color: rgb(29, 29, 29);
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: rgba(216, 216, 216, 0.062);
  --card-border-radius: 10px;
  --card-shadow: 10px 10px 20px -13px rgb(255, 255, 255);

  /* Minting Area */
  --minting-box-color: #00000096;
  --minting-box-heading-color: #73c2fb;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: #73c2fb;


  /* Minting Button */
  --minting-button-color: linear-gradient(180deg,#73c2fb 0%,#63b8f5 100%);
  --minting-button-text-color: black;

}







.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}
body {
  color: var(--main-text-color);
  position: relative;
  background: var(--main-background-color);
  margin: 0;
  font-family: 'Underdog', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  background: var(--nav-background-color);
  width: 100%;
  height: var(--nav-height);
  transition: height 0.2s ease-in-out;
  margin-bottom: 20px;
  z-index: 10;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 900px;
  margin: auto;

}

.nav-logo {
  width: var(--nav-logo-width);
  z-index: 10;
}

nav a {
  font-size: var(--nav-logo-font-size);
  color: var(--nav-link-color);
  text-decoration: none;

  padding: 20px;
  z-index: 10;
  font-weight: 600;
}

nav a:hover {
  color: var(--nav-link-hover-color);
  background-color: var(--nav-link-hover-background-color);
}

.mobile-menu-button {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 37px;
  right: -50px;
  transition: right 0.2s ease-in-out;
  z-index: 45;
  cursor: pointer;
}

.open-menu {
  left: 0px !important;
}

.mobile-nav {
  position: fixed;
  min-height: 100vh;
  background: rgb(117, 69, 69);
  background: var(--mobile-nav-background-color, rgb(117, 69, 69));
  left: -100%;
  z-index: 1000;
  width: 100%;
  transition: left 0.3s ease;
  
}

.mobile-nav-close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.mobile-nav-close-button img {
  height: 100%;
  width: 100%;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 40px;
  list-style: none;
}
path {
  fill: white;
}
.mobile-nav li {
  list-style: none;
  padding: 20px;
}

.mobile-nav a {
  text-decoration: none;
  color: var(--mobile-nav-link-color);
  font-size: var(--mobile-nav-link-size);
}

.mobile-nav a:hover {
  color: var(--mobile-nav-link-hover-color);
}

.mobile-nav-logo {
  height: var(--nav-logo-height);
}

.text-muted {
  color: darkgrey;
}

.nav-social {
  width: 30px;

  padding: 0 10px 0 10px;
  z-index: 10;
}

.social-icons {
  display: flex;
}

@media only screen and (max-width: 800px) {
  .hide-800 {
    display: none;
  }

  .mobile-menu-button {
    right: 37px;
  }

  .nav-logo {
    height: 50px !important;
  }

  nav {
    height: 100px;
  }
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: justify;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}
h1{
  color: black;
  font-weight: bolder;
}


.mintHeader {
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

@media only screen and (min-width: 800px) {
  header {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    grid-gap: 20px;
    gap: 20px;
  }
}

.text-primary-color {
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: var(--text-secondary-color);
}
#faqcolor{
  background-color:#6e3cbc;
}
#faq{
  max-width: 70%;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 0;
  background-color: #6e3cbc;
}
.Accordion{
  padding-top: 20px;
}

.rainbow-gallery {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.rainbow-gallery img {
  width: 100%;
}


.pad-card{

  margin-left: 30px;
  
}
.team-member{
  background-color:#e4e4e44d!important;
  border: 5px solid #6e3cbc !important;
}
.pad-card:first-of-type{

  margin-left: 0px;
}
#team-section{
  margin-top: 200px;
  margin-bottom: 100px;
}
.team {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  max-width: 70%;
  margin-top: 20px;
}

.welcome {
  display: block;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
  position: relative;
  background-color: transparent;
}
.welcome-left {
  max-width: 700px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
margin-left: auto;
margin-right: auto;
}


.container {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.content-wrapper {
  
  width:100%;
  margin: auto;
}
.main-banner{
  background-image: url(/static/media/foxcollage.907102bc.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 830px;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 3px solid black;
  border-left: 3px solid black;
  border-right: 3px solid black;
}
.welcome-over{
  width: 100%;
  min-height: 1010px;
  padding-bottom: 40px;
  background-color: rgba(109, 109, 109, 0.88);
  position: absolute;
  z-index: 0;
}

@media (max-width: 600px){

    .welcome{
      display: block;
      padding: 10px;
    }
    .welcome-left{
      max-width: 100%;
      padding-bottom: 10px;
    }
    .custom-mint-container>img{
      height: 250px;
    }
    #story{
      display: block!important;
      padding: 20px!important;
    }
    .story-left{
      max-width: 100%!important;
      padding-bottom: 40px!important;
    }
    #story > img{
      width: 300px!important;
      width: 95%!important;

    }
    
}

.join-community {
  max-width: 1140px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 2px solid black;
  height: 120px;
  z-index: 10;
  position: relative;
  top: 45px;
  box-shadow: 8px 8px 0 0 black;
  border-radius: 5px;
}
.join-community a {
  color: black;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg,#7267CB 0%,#6E3CBC 100%)!important;
  width: 409px;
  text-decoration: none;
  font-weight: 700;
  font-family: 'Underdog', cursive !important;
  height: 51px;
  border: 2px solid black;
  box-shadow: 8px 8px 0 0 black;
  border-radius: 0;
  border-radius: 5px;
}
.join-community a svg {
  margin-left: 10px;
  width: 23px;
  height: 17px;
}
#mint{
  padding-top: 20px;
}
.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-box {
  background-color: var(--minting-box-color) !important;
  color: var(--minting-box-text-color) !important;
}

.minting-box h5 {
  color: var(--minting-box-heading-color);
}

.minting-info {
  background-color: var(--minting-box-info-box-color);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-info h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.minting-button {
  background: var(--minting-button-color) !important;
}

.custom-mint-container {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 87%;
 }



.loading:after {
  content: " .";
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

#story{
  padding-top: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: transparent;
    margin: 0 auto;
    text-align: justify;
    padding: 150px 0;
    margin-top: 100px;
}
.story-left{
  text-align: justify;
  font-family: 'Underdog', cursive !important;
  width: 655px;
  font-size: 22px;

  margin-right: 100px;
}
#story > img{
  width: 400px;
  box-shadow: 15px -15px 0 0 #6e3cbc  ;
}

#story p{
    color: rgb(0, 0, 0);
}
#story h1{
  color: rgb(0, 0, 0);
}

.roadmap ul{
  margin-left: 10px;
}
.roadmap p {
  padding: 20px;
}
.roadmap div {
  margin-bottom: 20px;
}
#roadmap{
  background-color: #6e3cbc; 
}
.roadmap {
  max-width: 900px;
  margin: auto;
  align-items: center;
  padding: 20px;
  margin-top: 100px;
}
.vertical-timeline-element-content{
  width: 60%!important;
  left: -150px;
  margin-right: -300px !important;
  box-shadow: none!important;
}
.vertical-timeline-element-icon{
  width: 30px !important;
  height: 30px !important;
  margin-left: -15px!important;
  box-shadow: none!important;
  top: 15px!important;
}
.vertical-timeline::before{
  background: #000 !important;
}

.footer{
  background-color: var(--main-background-color);
 position: absolute;
 border-top: 1px solid #bffc77 ;
 width: 100%;
 color: #6e3cbc ;
 font-size: 15px;
 padding-bottom: 15px;
 text-align: right;
}
.text-footer{
  padding-top: 10px;
  padding-right: 40px;
}

.question-styles h3 {
  margin: 0;
  margin-right: 10px;
  display: flex;
  align-items: flex-start;
  line-height: 130%;
}

.faq-arrow {
  position: absolute !important;
  z-index: 10000000;
  right: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  font-size: 30px;
  color: white;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.MuiAccordionDetails-root {
  margin-left: 40px !important;
}
.MuiTypography-body1 {
  display: flex !important;
  align-items: flex-start !important;
}
.faq-arrow.active {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.faq-item {
  padding: 10px 40px !important;
  background-color: #73c2fb  !important;
  text-align: left !important;
  margin: 0 auto !important;
  box-sizing: border-box !important;
  position: relative !important;
  margin-bottom: 5vh !important;
  box-shadow: 15px 15px 0 rgba(255, 255, 255, 1) !important;
}

.MuiAccordionDetails-root {
  color: white !important;
}
.faq-component h1 {
  font-family: disBold !important;
  color: white;
  font-size: 45px;
  margin-bottom: 8vh;
}
.accordion-wrpper {
  width: 1300px;
  margin: 0 auto;
}
.faq-component {
  padding: 50px 0;
  background: #6e3cbc;
}
.MuiTypography-root {
  font-family: 'Underdog', cursive !important;
  font-size: 20px !important;
  color: black;
}
.question-styles {
  font-size: 28px !important;
  font-weight: 700 !important;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1400px) {
  .accordion-wrpper {
    width: 930px;
  }
}
@media screen and (max-width: 1200px) {
  .faq-component h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 990px) {
  .accordion-wrpper {
    width: 690px;
  }
}
@media screen and (max-width: 768px) {
  .accordion-wrpper {
    width: 510px;
  }
  .arrow {
    width: 20px;
  }
}
@media screen and (max-width: 576px) {
  .accordion-wrpper {
    width: 90%;
  }
  .MuiAccordionDetails-root {
    margin-left: 30px !important;
  }
  .MuiTypography-root {
    font-size: 18px !important;
  }
  .question-styles {
    font-size: 22px !important;
  }
  .faq-item {
    padding: 10px !important;
    box-shadow: 8px 8px 0 white !important;
  }
  .vertical-timeline-element-content{
    margin-left: 210px!important;
  }
  .vertical-timeline-element-icon{
    top: 8px!important;
    margin-left: 5px!important;
  }
  .team{
    display: block;
  }
  .team-member{
    margin-bottom: 20px;
  }
  .team-member:last-of-type{
    margin-bottom: 0px;
  }
  .pad-card{
    margin-left: 0px;
  }
  .about-title{
    font-size: 9vw!important;
  }
  #benefits{
    margin-top: 100px;
  }
}
.MuiTypography-colorTextSecondary {
  color: #73c2fb!important;
}
.about-title {
	font-size: 44px;
	color: #000 ;
	line-height: 1;
	margin-top: 0px;
  text-align: center;
  padding-left: 0px;
  font-weight: 700;
}
.container-card {
	display: flex;
	flex-wrap: wrap;
	justify-content: center
}
.container-card h5 {
	color: rgb(0, 0, 0);
  background-color:#6e3cbc ;

	font-size: 44px;
	font-weight: 700;
	text-align: center;

	line-height: 1;
	letter-spacing: -1px;
	padding-top: 30px;
  padding-bottom: 20px;
}
.text-left{
  text-align: left;
  padding: 10px;
}
.card {
	background: rgba(255, 255, 255, 0.856);
	max-width: 1200px;
	height: 860px;
	margin: 30px;
	margin-bottom: 70px;
  border: 5px solid #6e3cbc ;
}

.card ul{
color: rgb(0, 0, 0);
max-width: 80%;
font-size: 22px;;
margin-left: 30px;
}
.body2{
  text-align: center!important;
}
.columncard .card{
  height: 430px;
}
#communitycard .card{
  height: 350px!important;
}
.partners h1{
  text-align: center;
  font-size: 40px;
}
.partners-list{
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}
.partners-list>img{
  margin: 10px;
  
}
.seenon{
  margin-top: 50px;
}
.titlebuy a{
  text-decoration: none;
  color: black;
}
.titlebuy > img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.seenon-list>img{
  margin: 20px;
}
.nftsol{
  width:200px!important
}
.titlebuy h1{
    text-align: center;
}
.seenon h1{
  text-align: center;
  font-size: 40px;
}
.seenon-list{
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}
@media (max-width: 600px)
{
  .card{
    height: 1350px;
  }
  .columncard .card{
    width: 320px;
    height: 650px;
  }
  
}
@media (max-width: 380px)
{
  .firstbenefits{
    height: 1480px;
  }
}

